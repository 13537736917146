import React, { Component } from 'react';
import { Layout } from './Layout';
import MaterialTable from "material-table";
import TablePagination from '@material-ui/core/TablePagination';
import { DatePickerInput } from 'rc-datepicker';
import $ from 'jquery';
import Api from '../serviceCall';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CsvBuilder } from 'filefy';
import { isNullOrUndefined } from 'util';
import { MultiSelect } from "react-multi-select-component";
import "./Result.css";
import { TimeoutCounter } from './TimeoutCounter';
import _ from "lodash";
import Select from "react-dropdown-select";
import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@material-ui/core';
import AsyncSelect from "react-select/async";
import PropTypes from 'prop-types';

export class SummaryEdit extends Component {
    static displayName = SummaryEdit.name;
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            goBack: PropTypes.func.isRequired,
        }).isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            isModalopen: false, SearchList: [], StartDate: "", EndDate: "", Files: [], isEditModalopen: false, SelectedRows: [], Loading: true, toasterredCla: false, isOwn: false,
            isDownload: false, url: "", pdftitle: "", DateTyep: "", SearchType: "", ViewType: "", toasterCla: false, toaster: "", edate: false, sdate: false, dosd: false, doed: false, DateType: "", OrderFacilityIdTemp: "", IsOrderOfficeTemp: false, OrderFacilityId: "", assignedTo: "", orderOfficeId: "",
            ClickedAssignedTo: "",

            lblFirstName: "",
            lblLastName: "",
            lblDoctorFirstName: "",
            lblDoctorLastName: "",
            lblFacilityName: "",
            lblPolicyNum: "",
            lblPolicyAmount: "",

            RequiredFieldsList: [],
            maxDate: new Date(),
            minDate: "",
            FirstNameHide: false,
            LastNameHide: false,
            DoctorLastNameHide: false,
            DoctorFirstNameHide: false,
            FacilityNameHide: false,
            PolicyNumHide: false,
            PolicyAmountHide: false,
            IsDownloadforNewResults: false,

            UserId: "",
            offSet: 0,
            PageLimit: 5,
            totalRecord: 0,
            currentPage: 0,
            dataForExport: [],
            dataForSelectAll: [],
            isSearchClicked: false,
            isModalAgentopen: false,
            isModalAdjusteropen: false,
            Agents: [],
            Adjusters: [],
            AgentOptions: [],
            PopAgentId: "",
            PopAgentName: "",
            dopopAgentname: false,
            IsAgentId: "",
            removeAgentAlert: false,
            InitialAgentList: [],
            InitialAgentValues: [],
            SelectedAgent: "",
            AgentList: [],


            srViewType: "",
            srSearchType: "",
            srDateType: "",
            srStartDate: "",
            srEndDate: "",
            emptyDataSourceMessage: "Click on search button to see orders ready for review.",
            orderingOfficeAccessList: [],
            selectedMultiItem: [],
            hideExportButton: false,
            sortOrder: '',
            sortColumn: '',
            clickCount: 0

        };
        this.handleSTChange = this.handleSTChange.bind(this);
        this.handleVTChange = this.handleVTChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.onClickReview = this.onClickReview.bind(this);
        this.onReassignClick = this.onReassignClick.bind(this);
        this.handleSDChange = this.handleSDChange.bind(this);
        this.handleEDChange = this.handleEDChange.bind(this);
        this.handleDTChange = this.handleDTChange.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.GetRequiredFields = this.GetRequiredFields.bind(this);
        this.getGridValues = this.getGridValues.bind(this);
        this.exportGridValues = this.exportGridValues.bind(this);
        this.GetDataForSelectAll = this.GetDataForSelectAll.bind(this);
        this.loadOrderingOfficeAccessList = this.loadOrderingOfficeAccessList.bind(this);
        this.setSelectedfun = this.setSelectedfun.bind(this);
        this.Onclickexportpopup = this.Onclickexportpopup.bind(this);
        this.CheckForExport = this.CheckForExport.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.tableRef = React.createRef();
        this.handlePageChange = this.handlePageChange.bind(this);
        this.formatDateToString = this.formatDateToString.bind(this);
        this.handlePopAgentNameChange = this.handlePopAgentNameChange.bind(this);
        this.getAgentOptions = _.debounce(this.getAgentOptions.bind(this), 500);
        this.handleReassign = this.handleReassign.bind(this);
        this.onClickclosePopup = this.onClickclosePopup.bind(this);
        this.toasterredfun = this.toasterredfun.bind(this);
        this.toasterredfun2 = this.toasterredfun2.bind(this);
    }
    handlePageChange(e) {
        let CurrentPageLSR = localStorage.getItem('ClickedPageInResult');

        if (e === "first_page" || e === "0") {
            CurrentPageLSR = 1;
        }
        else if (e === "chevron_left") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronleftInResult')) + 1;
        }
        else if (e === "chevron_right") {
            CurrentPageLSR = parseInt(localStorage.getItem('ChevronrightInResult')) + 1;
        }
        else if (e === "last_page") {
            let lp = localStorage.getItem('TotalRecordsInResult') / localStorage.getItem('PageLimit');
            lp = Math.ceil(lp) - 1;
            if (lp === 0) {
                CurrentPageLSR = 1;
            }
            else {
                CurrentPageLSR = parseInt(lp) + 1;
            }
        }
        localStorage.setItem('ClickedPageInResult', CurrentPageLSR);
    }
    setSelectedfun(e) {
        this.setState({ selectedMultiItem: e });
    }

    loadOrderingOfficeAccessList(selectDropDownUsers) {
        let currentComponent = this;
        currentComponent.setState({ orderingOfficeAccessList: [], Loading: true });
        let productAPS = false;
        let productSummary = false;
        Api.Post('api/placeorder/GetOrderingOfficeAccessList', {
            AccessLevel: selectDropDownUsers
        }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                if (!isNullOrUndefined(result.result) && !isNullOrUndefined(result.productType)) {
                    if (result.productType.toLowerCase() === "aps") {
                        productAPS = true;
                    } else if (result.productType.toLowerCase() === "summary") {
                        productSummary = true;
                    } else if (result.productType.toLowerCase() === "both") {
                        productAPS = true;
                        productSummary = true;
                    }
                    let filteredOrderingOfficeAccessList = result.result.filter(item => {
                        let isAPS = item.aps && item.aps.toString().toLowerCase() === "true";
                        let isSummary = item.summary && item.summary.toString().toLowerCase() === "true";

                        return (productAPS && isAPS) || (productSummary && isSummary);
                    });
                    currentComponent.setState({
                        orderingOfficeAccessList: filteredOrderingOfficeAccessList,
                        unFilteredOrderingOfficeList: result.result,
                        selectedMultiItem: filteredOrderingOfficeAccessList,
                        Loading: false
                    });
                }
                else {
                    currentComponent.setState({ orderingOfficeAccessList: [], Loading: false });
                }
            }
            else {
                currentComponent.setState({ orderingOfficeAccessList: [], Loading: false });
            }
        });
    }

    handleSDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ dosd: true });
            document.getElementById("sdate").value = "";
            this.setState({ StartDate: "" });
        } else {
            this.setState({ dosd: false, StartDate: e });
        }
        if (e <= this.state.EndDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType !== "ReviewDate") {
            if (this.state.StartDate === "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }
    handleEDChange(e) {
        if (e && e instanceof Date) {
            e.setHours(0, 0, 0, 0);
        }
        if (e > this.state.maxDate || e < this.state.minDate) {
            this.setState({ doed: true });
            document.getElementById("edate").value = "";
            this.setState({ EndDate: "" });
        } else {
            this.setState({ doed: false, EndDate: e });
        }
        if (e >= this.state.StartDate) {
            this.setState({ dosd: false, doed: false });
        }
        if (this.state.DateType !== "ReviewDate") {
            if (this.state.StartDate === "") {
                this.setState({ DateType: "OrderDate" });
            }
        }
    }

    toasterredfun2(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
            }.bind(this), 5000);
    }
    componentWillMount() {
        let currentComponent = this;
        let MinDate = new Date(new Date().getFullYear() - 110, 1, 1);
        let MaxDate = new Date();
        MaxDate.setHours(0, 0, 0, 0);
        MinDate.setHours(0, 0, 0, 0);

        this.setState({ minDate: MinDate, maxDate: MaxDate });

        this.GetRequiredFields();

        let IsRedirectedFromCaseDetails = localStorage.getItem('IsFromCaseDetailsToSummaryEdit');
        if (IsRedirectedFromCaseDetails === "true") {
            localStorage.removeItem('IsFromCaseDetailsToSummaryEdit');
            let selectedRows = JSON.parse(localStorage.getItem('SelectedRows'));
            let orderingOfficeAccessList = JSON.parse(localStorage.getItem('OrderingOfficeOptionsList'));
            let selectedOrderingOfficeList = JSON.parse(localStorage.getItem('SelectedOrderingOfficeList'));
            let resultsSortColumn = localStorage.getItem('ResultsSortColumn');
            let resultsSortOrder = localStorage.getItem('ResultsSortOrder');
            const pageLimit = parseInt(localStorage.getItem('PageLimit'), 10);
            const offset = parseInt(localStorage.getItem('OffSet'), 10);
            let vPageLimit;
            let vOffset;

            if (!isNaN(pageLimit)) {
                vPageLimit = pageLimit;
            } else {
                vPageLimit = 5;
            }
            if (!isNaN(offset)) {
                vOffset = offset;
            } else {
                vOffset = 0;
            }
            this.handlePageChange(localStorage.getItem('ClickedPageInResult'));
            this.setState({
                SearchType: localStorage.getItem('SearchType'),
                ViewType: localStorage.getItem('ViewType'),
                DateType: localStorage.getItem('DateType'),
                StartDate: localStorage.getItem('StartDate'),
                EndDate: localStorage.getItem('EndDate'),
                orderingOfficeAccessList: orderingOfficeAccessList,
                selectedMultiItem: selectedOrderingOfficeList,
                SelectedRows: selectedRows,
                pageLimit: vPageLimit,
                offset: vOffset,
                currentPage: parseInt(localStorage.getItem('ClickedPageInResult')) - 1,
                srViewType: localStorage.getItem('ViewType'),
                srSearchType: localStorage.getItem('SearchType'),
                srDateType: localStorage.getItem('DateType'),
                srStartDate: localStorage.getItem('StartDate'),
                srEndDate: localStorage.getItem('EndDate'),
                sortColumn: resultsSortColumn,
                sortOrder: resultsSortOrder,
                UserId: this.state.userId,
                isSearchClicked: true,
                Loading: true
            }, () => { this.getGridValues(this.state.UserId, vOffset, vPageLimit, resultsSortColumn, resultsSortOrder); });
        }
        else {
            let UserType = localStorage.getItem("UserType");
            if (UserType === "Agent") {
                currentComponent.setState({ SearchType: "MyOrders" });
                currentComponent.loadOrderingOfficeAccessList(1);
            }
            else {
                currentComponent.setState({ SearchType: "AllOfficeOrders" });
                currentComponent.loadOrderingOfficeAccessList(2);
            }
            this.setState({ UserId: this.state.userId, offSet: 0, PageLimit: 5, Loading: true });
        }

        Api.Get('api/placeorder/GetIsOwnStatus', currentComponent).then(function (result) {
            localStorage.removeItem('ShowAdminMenu');
            localStorage.removeItem('UserType');
            localStorage.removeItem('PlaceOrder');
            localStorage.removeItem('ViewResults');
            localStorage.removeItem('ViewAuth');
            localStorage.removeItem('ExportSearchResults');
            localStorage.removeItem('PerformanceMetrics');
            localStorage.setItem('ShowAdminMenu', result.isAdmin);
            localStorage.setItem('UserType', result.userType);
            localStorage.setItem('PlaceOrder', result.placeOrder);
            localStorage.setItem('ViewResults', result.viewResults);
            localStorage.setItem('ViewAuth', result.viewAuth);
            localStorage.setItem('ExportSearchResults', result.exportSearchResults);
            localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
            let UserId = Api.decryptData(result.userId);
            currentComponent.setState({ SearchList: [], userId: UserId });
            if (result.statusCode === 100) {
                currentComponent.setState({ Loading: false, isOwn: result.isOwn, hideExportButton: result.exportSearchResults });
            }
            else {
                currentComponent.setState({ Loading: false });
            }
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });

    }
    async GetInitial(OrderOfficeId, AssignedTo) {
        let currentComponent = this;
        currentComponent.setState({ Loading: true, ClickedAssignedTo: AssignedTo });
        await Api.Post(
            "api/placeorder/GetInitialAgentsLists",
            { "OrderingOfficeAccess": OrderOfficeId },
            currentComponent
        )
            .then(function (result) {
                if (result.isAccessible) {
                    currentComponent.setState({ Agents: result.agentList, Loading: false });
                    currentComponent.setState({ InitialAgentList: result.agentList });
                    let userId = Api.decryptData(result.userId);
                    currentComponent.setState({ UserId: userId });

                    currentComponent.state.Agents.forEach((doc, index) => {
                        if (doc.combinedName != AssignedTo) {
                            currentComponent.setState({
                                AgentOptions: [
                                    ...currentComponent.state.AgentOptions,
                                    { label: doc.combinedName, value: "" + doc.id, assignedTo: doc.userId },
                                ],
                            });
                        }
                    });

                    currentComponent.setState({
                        InitialDocValues: currentComponent.state.Options,
                        InitialAgentValues: currentComponent.state.AgentOptions
                    });
                    currentComponent.setState({ Loading: false });
                }
            })
            .catch(function (error) {
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2(
                        "Check your internet connection."
                    );
                } else {
                    currentComponent.toasterredfun2(error);
                }
                if (!window.location.href.includes("=")) {
                    currentComponent.setState({ Loading: false });
                }
            });
    }


    async GetDataForSelectAll() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked === true) {
            currentComponent.setState({ Loading: true });
            let officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetSummaryEditList',
                { "isInitial": true, "UserId": this.state.userId, "dataOffSet": "0", "dataLimit": "0", "ViewType": currentComponent.state.srViewType, "DateType": currentComponent.state.srDateType, "SelectionType": currentComponent.state.srSearchType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList, "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    result.result.forEach(rowData => {
                        rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                        rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                        rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                        rowData.patientPolicyAmount = Api.decryptData(rowData.patientPolicyAmount);
                    });
                    currentComponent.setState({ Loading: false, dataForSelectAll: result.result });
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                }
                else {
                    currentComponent.setState({ Loading: false, dataForSelectAll: [] });
                }
                responce = true;
            }).catch(function (error) {
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                    responce = false;
                }
                else {
                    currentComponent.toasterredfun2(error);
                    responce = false;
                }
                currentComponent.setState({ Loading: false, dataForSelectAll: [] });
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    async exportGridValues() {
        let currentComponent = this;
        let responce;
        if (currentComponent.state.isSearchClicked === true) {
            currentComponent.setState({ Loading: true });
            let officeNameList = "0";
            const isExportExcelPDF = true
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            await Api.Post('api/placeorder/GetSummaryEditList',
                { "isInitial": true, "UserId": this.state.userId, "dataOffSet": "0", "dataLimit": "0", "ViewType": currentComponent.state.srViewType, "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList, "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    if (result.exportSearchResults) {
                        result.result.forEach(rowData => {
                            rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                            rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                            rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                            rowData.patientPolicyAmount = Api.decryptData(rowData.patientPolicyAmount);
                        });
                        currentComponent.setState({ Loading: false, dataForExport: result.result });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                    }
                    else {
                        currentComponent.setState({ dataForExport: [], Loading: false });
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        if (currentComponent.state.hideExportButton) {
                            currentComponent.setState({ exportAlert: true });
                        }
                        responce = false;
                        return responce;
                    }
                }
                else {
                    currentComponent.setState({ Loading: false, dataForExport: [] });
                }
                responce = true;
            }).catch(function (error) {
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                    responce = false;
                }
                else {
                    currentComponent.toasterredfun2(error);
                    responce = false;
                }
                currentComponent.setState({ Loading: false, dataForExport: [] });
            });
        }
        else {
            responce = false;
        }
        return responce;
    }

    getGridValues(userId, offSet, dataLimit, fieldName, sortOrder) {
        let currentComponent = this;
        if (currentComponent.state.isSearchClicked) {
            currentComponent.setState({ Loading: true, offSet: offSet, PageLimit: dataLimit });
            localStorage.setItem('PageLimit', dataLimit);
            localStorage.setItem('OffSet', offSet);
            let officeNameList = "0";
            if (currentComponent.state.selectedMultiItem.length > 0) {
                officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
            }
            Api.Post('api/placeorder/GetSummaryEditList',
                {
                    "isInitial": true, "dataOffSet": offSet, "dataLimit": dataLimit, "ViewType": currentComponent.state.srViewType,
                    "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType, "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate),
                    "officeName": officeNameList, "fieldName": fieldName, "sortOrder": sortOrder
                }, currentComponent
            ).then(function (result) {
                if (!isNullOrUndefined(result.result)) {
                    if (result.totalRecords == 0) {
                        currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                    }
                    result.result.forEach(rowData => {
                        rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                        rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                        rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                        rowData.patientPolicyAmount = Api.decryptData(rowData.patientPolicyAmount);
                    });
                    currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: result.result, isOwn: result.isOwn, totalRecord: result.totalRecords });

                    localStorage.setItem('TotalRecordsInResult', result.totalRecords)
                    localStorage.removeItem('ExportSearchResults');
                    localStorage.removeItem('ShowAdminMenu');
                    localStorage.removeItem('UserType');
                    localStorage.removeItem('PlaceOrder');
                    localStorage.removeItem('ViewResults');
                    localStorage.removeItem('ViewAuth');
                    localStorage.removeItem('PerformanceMetrics');
                    localStorage.setItem('ShowAdminMenu', result.isAdmin);
                    localStorage.setItem('UserType', result.userType);
                    localStorage.setItem('PlaceOrder', result.placeOrder);
                    localStorage.setItem('ViewResults', result.downloadImages);
                    localStorage.setItem('ViewAuth', result.viewAuth);
                    localStorage.setItem('ExportSearchResults', result.exportSearchResults);
                    localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

                    const newPageLimit = dataLimit;
                    if (currentComponent.tableRef.current) {
                        const event = {
                            target: {
                                value: newPageLimit,
                            },
                        };
                        currentComponent.tableRef.current.onChangeRowsPerPage(event);
                    }
                    localStorage.removeItem('IsFromCaseDetailsToSummaryEdit');
                }
                else if (result === null || result.statusCode === 429) {
                    currentComponent.setState({ Loading: false });
                    currentComponent.toasterredfun2("The number of consecutive mouse clicks exceeded. Please refresh the page or wait 7 seconds and try again.");
                }
                else {
                    currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: [], isOwn: result.isOwn, totalRecord: 0, emptyDataSourceMessage: "No records to display" });
                }
            }).catch(function (error) {
                if (error === "TypeError: Failed to fetch") {
                    currentComponent.toasterredfun2("Check your internet connection.");
                }
                else {
                    currentComponent.toasterredfun2(error);
                }
                currentComponent.setState({ Loading: false, SearchList: [], totalRecord: 0, emptyDataSourceMessage: "No records to display" });
            });
        }
    }
    GetRequiredFields() {
        let CompanyId = 1;
        let currentComponent = this;
        currentComponent.setState({ Loading: true });
        Api.Post('api/placeorder/GetRequiredFields', { "CompanyId": CompanyId }, currentComponent).then(function (result) {
            currentComponent.setState({ RequiredFieldsList: result.requiredFields });

            if (currentComponent.state.RequiredFieldsList.length > 0) {
                for (const field of currentComponent.state.RequiredFieldsList) {
                    currentComponent.setState({
                        [`${field.className}Hide`]: field.isActive,
                    });

                    currentComponent.setState({
                        [`lbl${field.className}`]: field.customFieldName === "" ? field.originalFieldName : field.customFieldName, Loading: false
                    });
                }
            }

        }).catch(function (error) {
            currentComponent.setState({ loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
        });
    }

    handleSTChange(e) {
        this.setState({ SearchType: e.target.value, selectedMultiItem: [] });
        let user = localStorage.getItem("UserType");
        if (user === "Agent") {
            if (e.target.value === "MyOrders") {
                this.loadOrderingOfficeAccessList(1);
            }
            else {
                this.loadOrderingOfficeAccessList(2);
            }
        }
        else {
            this.loadOrderingOfficeAccessList(2);
        }
    }
    handleVTChange(e) {
        this.setState({ ViewType: e.target.value });
    }
    handleDateChange(e) {
        this.setState({ DateType: e.target.value });
        if (this.state.StartDate === "") {
            this.setState({ dosd: true });

        }
        if (this.state.EndDate === "") {
            this.setState({ doed: true });
        }
    }
    handleDTChange(e) {
        this.setState({ DateTyep: e.target.value });
        if (e.target.value === "All") {
            this.setState({ StartDate: "", EndDate: "" });
            document.getElementById("sdate").value = "";
            document.getElementById("edate").value = "";
        }
    }
    handleSubmitSearch(e) {
        let isvalid = true;
        if (this.state.DateType === "OrderDate" || this.state.DateType === "ReviewDate") {
            if (this.state.StartDate === "") {
                isvalid = false;
                this.setState({ dosd: true });

            }
            if (this.state.EndDate === "") {
                isvalid = false;
                this.setState({ doed: true });
            }
        }
        if (this.state.EndDate === "Invalid date") {
            if (document.getElementById("edate").value.length !== 0) {
                isvalid = false;
                this.setState({ doed: true });
            }
            else {
                this.setState({ EndDate: "", doed: false });
            }
        }
        if (this.state.StartDate === "Invalid date") {
            if (document.getElementById("sdate").value.length !== 0) {
                isvalid = false;
                this.setState({ dosd: true });
            }
            else {
                this.setState({ StartDate: "", dosd: false });
            }
        }
        if (this.state.StartDate instanceof Date && !(this.state.EndDate instanceof Date)) {
            isvalid = false;
            this.setState({ doed: true });
            if (document.getElementById("edate").value.length !== 0) {
                this.setState({ EndDate: "" });
            }
        }
        if (!(this.state.StartDate instanceof Date) && this.state.EndDate instanceof Date) {
            isvalid = false;
            this.setState({ dosd: true });
            if (document.getElementById("sdate").value.length !== 0) {
                this.setState({ StartDate: "" });
            }
        }
        if (this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) > new Date(this.state.EndDate))) {
            this.setState({ dosd: true, doed: true });
            isvalid = false;
            this.toasterredfun2("Start Date should be less than or equal to End date");
        }
        if (isvalid) {
            if ((this.state.StartDate instanceof Date && this.state.EndDate instanceof Date && (new Date(this.state.StartDate) <= new Date(this.state.EndDate))) || this.state.SearchType.length != 0 || this.state.ViewType.length != 0) {
                isvalid = true;
            }
            else {
                isvalid = false;
                this.toasterredfun2("Provide any input to search");
            }
        }

        if (isvalid) {
            this.setState({ SelectedRows: [] });
            this.setState({
                sortColumn: '',
                sortOrder: '',
                clickCount: 0,
            });
            this.setState(prevState => ({
                currentPage: 0, isSearchClicked: true, srViewType: prevState.ViewType, srSearchType: prevState.SearchType, srDateType: prevState.DateType,
                srStartDate: document.getElementById("sdate").value, srEndDate: document.getElementById("edate").value
            }), () => { this.getGridValues(this.state.UserId, 0, this.state.PageLimit); });

            localStorage.setItem('ClickedPageInResult', 1);
        }

    }
    toasterredfun(e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.setState({ toasterredCla: true });
        this.setState({ toaster: e });
        setTimeout(
            function () {
                this.setState({ toasterredCla: false });
                this.props.history.goBack();
            }, 5000);
    }
    getResultsList = () => {
        let currentComponent = this;
        currentComponent.setState({ Loading: true, SearchList: [] });
        let officeNameList = "0";
        if (currentComponent.state.selectedMultiItem.length > 0) {
            officeNameList = currentComponent.state.selectedMultiItem.map(function (elem) { return elem.value; }).join(",");
        }
        Api.Post('api/placeorder/GetSummaryEditList', {
            "isInitial": true, "dataOffSet": currentComponent.state.offSet, "dataLimit": currentComponent.state.PageLimit,
            "ViewType": currentComponent.state.srViewType, "SelectionType": currentComponent.state.srSearchType, "DateType": currentComponent.state.srDateType,
            "StartDate": currentComponent.formatDateToString(currentComponent.state.srStartDate), "EndDate": currentComponent.formatDateToString(currentComponent.state.srEndDate), "officeName": officeNameList,
            "fieldName": currentComponent.state.sortColumn, "sortOrder": currentComponent.state.sortOrder
        }, currentComponent).then(function (result) {
            if (!isNullOrUndefined(result.result)) {
                if (result.totalRecords === 0) {
                    currentComponent.setState({ emptyDataSourceMessage: "No records to display" });
                }
                result.result.map(rowData => {
                    rowData.patientFirstName = Api.decryptData(rowData.patientFirstName);
                    rowData.patientLastName = Api.decryptData(rowData.patientLastName);
                    rowData.patientPolicyNumber = Api.decryptData(rowData.patientPolicyNumber);
                    rowData.patientPolicyAmount = Api.decryptData(rowData.patientPolicyAmount);
                });
                currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: result.result, isOwn: result.isOwn, totalRecord: result.totalRecords });
            }
            else {
                currentComponent.setState({ Loading: false, isDownload: result.isDownloadable, SearchList: [], isOwn: result.isOwn, totalRecord: 0, emptyDataSourceMessage: "No records to display" });
            }
        }).catch(function (error) {
            currentComponent.setState({ Loading: false });
            if (error === "TypeError: Failed to fetch") {
                currentComponent.toasterredfun2("Check your internet connection.");
            }
            else {
                currentComponent.toasterredfun2(error);
            }
            currentComponent.setState({ Loading: false, SearchList: [], totalRecord: 0, emptyDataSourceMessage: "No records to display" });
        });
    }
    handleClear(e) {
        this.setState({
            StartDate: "", EndDate: "", SearchType: "", DateTyep: "", ViewType: "", doed: false, dosd: false, SearchList: [], isSearchClicked: false,
            srViewType: "", srSearchType: "", srStartDate: "", srEndDate: "", totalRecord: "0", emptyDataSourceMessage: "Click on search button to see results",
            DateType: "", srDateType: ""
        });
        this.setState({
            sortColumn: '',
            sortOrder: '',
            clickCount: 0,
        });
        document.getElementById("sdate").value = "";
        document.getElementById("edate").value = "";
        let UserType = localStorage.getItem("UserType");
        if (UserType === "Agent") {
            this.setState({ SearchType: "MyOrders" });
            this.loadOrderingOfficeAccessList(1);
        }
        else {
            this.setState({ SearchType: "AllOfficeOrders" });
            this.loadOrderingOfficeAccessList(2);
        }
    }
    handleReassign(e) {
        let currentComponent = this;
        let SelectedAgent = this.state.SelectedAgent;
        currentComponent.setState({ OrderFacilityId: this.state.OrderFacilityId, assignedTo: this.state.assignedTo, Loading: true });
        if (SelectedAgent != "") {
            Api.Post('api/placeorder/UpdateAssignemntHistory', { "FacilityId": this.state.OrderFacilityId, "AssignedTo": this.state.assignedTo }, currentComponent).then(function (result) {
                if (result.statusCode === 100) {
                    currentComponent.setState({
                        Loading: false, isModalopen: false, OrderFacilityId: "", assignedTo: "", PopAgentId: "", PopAgentName: "", Agents: [], AgentOptions: [], AgentList: [], SelectedAgent: "", ClickedAssignedTo: ""
                    });
                    currentComponent.getResultsList();
                }
                else {
                    currentComponent.setState({ Loading: false });
                }
            });
        }
        else {
            currentComponent.setState({ Loading: false, dopopAgentname: true });
            currentComponent.toasterredfun2("Please select the User to Reassign");
        }
    }

    onClickclosePopup() {
        this.setState({
            isModalopen: false, SelectedAgent: "", SelectedAdjuster: "", Agents: [], AgentOptions: [],
            PopAgentId: "", PopAgentName: "", assignedTo: "", ClickedAssignedTo: ""
        });
    }

    onClickReview(Id) {
        let currentComponent = this;
        currentComponent.setState({ OrderFacilityId: Id, Loading: true });
        Api.Post('api/placeorder/UpdateReviewHistory', { "OrderFacilityId": Id }, currentComponent).then(function (result) {
            if (result.statusCode === 100) {
                currentComponent.setState({
                    Loading: false, OrderFacilityId: ""
                });
                window.open("https://eapsuat.enoahisolution.com/", "_blank");
            }
            else {
                currentComponent.setState({ Loading: false });
            }
        });

    }

    onReassignClick(OrderFacilityId, orderOfficeId, assignedTo) {
        let currentComp = this;
        currentComp.setState({ isModalopen: true, OrderFacilityId: OrderFacilityId, orderOfficeId: orderOfficeId, assignedTo: assignedTo });
        this.GetInitial(orderOfficeId, assignedTo);
        this.renderAgentList();
    }

    async CheckForExport() {
        let currentComponent = this;
        let responce = false;
        currentComponent.setState({ Loading: true });
        await Api.Get('api/placeorder/AllowExportValues', currentComponent).then(function (result) {
            if (result.statusCode == "100") {
                let hideExportAlert = false;
                let notAllow = false;
                if (!isNullOrUndefined(result)) {
                    if (result.allowExport) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ hideExportButton: result.allowExport });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);

                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        localStorage.removeItem('ShowAdminMenu');
                        localStorage.removeItem('UserType');
                        localStorage.removeItem('PlaceOrder');
                        localStorage.removeItem('ViewResults');
                        localStorage.removeItem('ViewAuth');
                        localStorage.removeItem('ExportSearchResults');
                        localStorage.removeItem('PerformanceMetrics');
                        localStorage.setItem('ShowAdminMenu', result.isAdmin);
                        localStorage.setItem('UserType', result.userType);
                        localStorage.setItem('PlaceOrder', result.placeOrder);
                        localStorage.setItem('ViewResults', result.downloadImages);
                        localStorage.setItem('ViewAuth', result.viewAuth);
                        localStorage.setItem('ExportSearchResults', result.allowExport);
                        localStorage.setItem('PerformanceMetrics', result.performanceMetrics);
                        if (currentComponent.state.hideExportButton) {
                            currentComponent.setState({ exportAlert: true });
                            hideExportAlert = true;
                        }
                        responce = false;
                        notAllow = true;
                    }
                    if (result.isDownload) {
                        currentComponent.setState({ Loading: false });
                        currentComponent.setState({ isDownload: result.isDownload });
                    }
                    else {
                        currentComponent.setState({ Loading: false });
                        if (currentComponent.state.isDownload) {
                            if (!hideExportAlert) {
                                currentComponent.setState({ exportAlert: true });
                            }

                        }
                        responce = false;
                        notAllow = true;
                    }

                    if (notAllow) {
                        return responce;
                    }

                }
                else {
                    currentComponent.setState({ Loading: false });
                }
                responce = true;
            } else {
                currentComponent.setState({ Loading: false });
                currentComponent.toasterredfun(result.statusMessage);
                responce = false;
            }
        });

        return responce;
    }

    renderAgentSearch() {
        return (
            <AsyncSelect
                cacheOptions
                //className="form-control"
                value={this.state.SelectedAgent}
                //selected={this.state.PopFacilityId}
                defaultOptions={this.state.AgentOptions}
                loadOptions={this.getAgentOptions}
                placeholder="Select User (Min 3 Chars)"
                onChange={this.handlePopAgentNameChange}
                noOptionsMessage={() => "No Users found"}
            />
        );
    }
    handlePopAgentNameChange(e) {
        if (e != null) {
            this.setState(
                {
                    SelectedAgent: e,
                    PopAgentName: e.label,
                    PopAgentId: e.value,
                    assignedTo: e.assignedTo,
                },
                () => {
                    // This will output an array of objects
                    // given by Autocompelte options property.
                }
            );
        }
    }

    getAgentOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        if (inputValue.length > 2) {
            let currentComponent = this;
            Api.Post(
                "api/login/AgentSearchList",
                { apstool: inputValue, OrderingOfficeId: this.state.orderOfficeId },
                currentComponent
            ).then(function (result) {
                if (result.statusCode == 403) {
                    currentComponent.setState({ loading: false });
                }
                else {
                    currentComponent.setState({
                        Agents: result.agentList,
                        AgentOptions: [],
                        AgentList: result.agentList,
                    });
                    let filteredAgents = currentComponent.state.Agents.filter(doc => doc.combinedName !== currentComponent.state.ClickedAssignedTo);
                    let agentOptions = filteredAgents.map(doc => ({
                        label: doc.combinedName,
                        value: "" + doc.id,
                        assignedTo: doc.userId,
                    }));
                    currentComponent.setState({
                        AgentOptions: agentOptions
                    });
                    callback(agentOptions);
                }
            });
        }
    };
    renderAgentList() {
        if (this.state.AgentList && this.state.AgentList.length > 0) {
            return (
                <div className="dropdown-menu">
                    {this.state.AgentList.map(listitem => (
                        <a className="dropdown-item"
                            onClick={() => this.onSelectAgent(listitem)}
                            key={listitem.id}
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13 || event.key == 13) {
                                    this.onSelectAgent(listitem);
                                }
                            }}>
                            {listitem.agentFN} {listitem.agentLN}
                        </a>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item">No Results</a>
                </div>
            );
        }
    }
    onSelectAgent = (agent) => {
        this.setState({ IsAgentId: agent.id });
        this.setState({
            AgentFN: agent.agentFN,
            AgentLN: agent.agentLN,
            AgentId: agent.agentId,
            AgId: agent.id,
            assignedTo: agent.userId,
        });
    }
    handleKeypress(event) {
        let key = event.which || event.key;
        if (key === 13 || key === "13") {
            this.handleSubmitSearch();
        }
    }
    onKeyPressValueStartDate(event) {
        let length = event.target.value.length;
        let n = event.target.value.includes("/");
        let m = event.target.value.slice(3, 5).includes("/");
        if (length === 1) {
            if (n) {
                this.setState({ StartDate: "" });
            }
        }
        if (length === 2) {
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length === 5) {
            if (m) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    Onclickexportpopup() {
        let currentComponent = this;
        currentComponent.setState({ exportAlert: false, Loading: false });
    }
    onKeyPressValueEndDate(event) {
        let length = event.target.value.length;
        let n = event.target.value.includes("/");
        let m = event.target.value.slice(3, 5).includes("/");
        if (length === 1) {
            if (n) {
                this.setState({ EndDate: "" });
            }
        }
        if (length === 2) {
            if (n) {
                event.target.value = "0" + event.target.value;
            }
        }
        if (length === 5) {
            if (m) {
                event.target.value = [event.target.value.slice(0, 3), "0", event.target.value.slice(3)].join();
            }
        }
        event.target.value = event.target.value
            .replace(/^(\d\d)(\d)$/g, "$1/$2")
            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
            .replace(/[^\d/]/g, "");
        if (length > 10) {
            event.stopPropagation();
        }
        this.handleKeypress(event);
    }
    handleColumnHeaderClick = (column) => {
        if (this.state.SearchList.length !== 0) {
            let offSet = (this.state.currentPage) * this.state.PageLimit;
            if (this.state.sortColumn === column.field) {
                this.setState(prevState => {
                    const newSortOrder = prevState.sortOrder === 'asc' ? 'desc' : 'asc';
                    this.setState({
                        sortOrder: newSortOrder,
                        sortColumn: column.field,
                    });
                }, () => {
                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, this.state.sortOrder);
                });
            } else {
                this.setState({
                    sortColumn: column.field,
                    sortOrder: 'asc',
                }, () => {
                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, column.field, "asc");
                });
            }
        }
    }
    formatDateToString(dt) {
        if (dt.toString().length !== 0) {
            let date = new Date(dt);
            let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            let yyyy = date.getFullYear();
            return (MM + "/" + dd + "/" + yyyy);
        }
        else {
            return "";
        }
    }
    render() {
        let landing = 'img/ems-logo.gif';
        let companyType = localStorage.getItem("CompanyType");
        let columns = [

            { title: 'Order Date', field: 'createdOnDateTime', type: 'date' },
            { title: 'Review Ready Date', field: 'reviewDate', type: 'date' },
            { title: 'eNoah Order Number', field: 'eNoahOrderId' },
            { title: 'Patient ' + this.state.lblFirstName, field: 'patientFirstName', hidden: this.state.FirstNameHide === false },
            { title: 'Patient ' + this.state.lblLastName, field: 'patientLastName', hidden: this.state.LastNameHide === false },
            { title: this.state.lblDoctorFirstName, field: 'doctorFirstName', hidden: this.state.DoctorFirstNameHide === false },
            { title: this.state.lblDoctorLastName, field: 'doctorLastName', hidden: this.state.DoctorLastNameHide === false },
            { title: this.state.lblFacilityName, field: 'facilityName', hidden: this.state.FacilityNameHide === false },
            { title: this.state.lblPolicyNum, field: 'patientPolicyNumber', hidden: this.state.PolicyNumHide === false },
            { title: this.state.lblPolicyAmount, field: 'patientPolicyAmount', hidden: this.state.PolicyAmountHide === false },
            { title: 'Assigned To ', field: 'assignedTo' },
            { title: 'Id ', field: 'id', hidden: true },
            { title: 'OrderOfficeId ', field: 'orderOfficeId', hidden: true },
            {
                title: 'Action ', field: 'action', disableClick: true, disableSorting: true,
                render: rowData => <span style={{ "width": "150px", "display": "block" }}><span className="edit-btnm editActionE" aria-hidden="true" onClick={(e) => this.onReassignClick(rowData.id, rowData.orderOfficeId, rowData.assignedTo)}>Reassign</span>
                    <span className="edit-btnm" aria-hidden="true" onClick={(e) => this.onClickReview(rowData.id)}>Review</span> </span>
            }
        ];
        let visibleColumns = (columns.filter((column) => !column.hidden));
        return (
            <Layout>
                <TimeoutCounter />
                <span className={"loading " + (this.state.Loading ? "show" : "hide")} ><span className="loading-div-d"></span> <img src={process.env.PUBLIC_URL + landing} /> </span>
                <div className={"toasterred-div " + (this.state.toasterredCla ? "show" : "hide")}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.toaster} </div>
                <div className={"removeAlert-divbrowser " + (this.state.exportAlert ? "show" : "hide")}>
                    <span className="alert-bg-div"></span>
                    <div className="alert-pos-x text-center">
                        <img src="img/error.png" alt="Error" />
                        <p className="my-3"> Your permissions has been modified/revoked to perform this action. Please contact your administrator.</p>
                        <div className="text-center-div m-0">
                            <div className="bttn popupButton m-0" aria-hidden="true" onClick={this.Onclickexportpopup}>
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hgtDiv bg-whitee mt-2 p-3'>
                    <div className="">
                        <div className='row'>
                            <div className='border-md col-xl-8 bg-white mb-3'>
                                <div className="col-xl-12 pull-left mt-2">
                                    <div className="mb-1">
                                        <div className="">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 pull-left nopadding">
                                                            <span className="result-title-xds">Selection </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 nopadding radioLable-x">
                                                                <input type="radio" id="SelectionType" name="customedate1" value="MyOrders" onChange={this.handleSTChange} checked={this.state.SearchType == "MyOrders"} />
                                                                <label className="small" htmlFor="SelectionType">My Orders</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="SelectionType" name="customedate1" value="AllOfficeOrders" onChange={this.handleSTChange} checked={this.state.SearchType == "AllOfficeOrders"} />
                                                                <label className="small" htmlFor="SelectionType">All Orders</label>
                                                            </div>
                                                            <div className="form-group pull-left w-50">
                                                                <MultiSelect className="form-control" style={{ "z-index": "9999" }}
                                                                    options={this.state.orderingOfficeAccessList}
                                                                    value={this.state.selectedMultiItem}
                                                                    onChange={this.setSelectedfun}
                                                                    labelledBy={"Select Office"}
                                                                    hasSelectAll={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 pull-left nopadding">
                                                            <span className="result-title-xds">View </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-35 nopadding radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="NewReviews" onChange={this.handleVTChange} checked={this.state.ViewType == "NewReviews"} />
                                                                <label className="small" htmlFor="ViewType">New Reviews</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="AllReviews" onChange={this.handleVTChange} checked={this.state.ViewType == "AllReviews"} />
                                                                <label className="small" htmlFor="ViewType">All Reviews</label>
                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="ViewType" name="customedate2" value="MyReviews" onChange={this.handleVTChange} checked={this.state.ViewType == "MyReviews"} />
                                                                <label className="small" htmlFor="ViewType">My Review</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 nopadding">
                                                    <div className="form-row-xm">
                                                        <div className="col-md-2 nopadding pull-left">
                                                            <span className="result-title-xds"> Date Range </span>
                                                        </div>
                                                        <div className="col-md-10 pull-left nopadding">
                                                            <div className="form-group pull-left mr-6 nopadding radioLable-x">
                                                                <input type="radio" id="DateType" name="customedate3" value="OrderDate" onChange={this.handleDateChange} checked={this.state.DateType == "OrderDate"} />
                                                                <label className="small" htmlFor="DateType">Order Date</label>

                                                            </div>
                                                            <div className="form-group pull-left mr-6 radioLable-x">
                                                                <input type="radio" id="DateType" name="customedate3" value="ReviewDate" onChange={this.handleDateChange} checked={this.state.DateType == "ReviewDate"} />
                                                                <label className="small" htmlFor="DateType">Review Date</label>
                                                            </div>
                                                            <div className="col-md-3 nopadding pull-left">
                                                                <div className="form-group pull-left mr-35">
                                                                    <div className='input-group dop-d result-date result-calendar-icon-startdate'>
                                                                        <label className="small mr-1 mtop7" htmlFor="last5years">Start Date</label>
                                                                        <DatePickerInput selected={this.state.StartDate} id="sdate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueStartDate.bind(this)} value={this.state.StartDate} onChange={this.handleSDChange} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.dosd ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 nopadding pull-left">
                                                                <div className="form-group pull-left mr-35">
                                                                    <div className='input-group dop-d result-date result-calendar-icon-enddate' id='datetimepicker1'>
                                                                        <label className="small mr-1 mtop7" htmlFor="last5years">End Date</label>
                                                                        <DatePickerInput selected={this.state.EndDate} id="edate" autoComplete="off" maxLength="10" onKeyPress={this.onKeyPressValueEndDate.bind(this)} onChange={this.handleEDChange} value={this.state.EndDate} dateformat="MM/dd/yyyy" className={"form-control " + (this.state.doed ? "redborder" : "blackborder")} maxDate={new Date()} minDate={new Date(1920, 1, 1)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-1 pull-left mt-2">
                                                    <button className="btn btn-blue-btnnm btn-type hvr-shutter-out-horizontal pull-right mt-1 mb-1 " onClick={this.handleClear}>Clear</button>
                                                    <button className="btn btn-blue-btnnm btn-type hvr-shutter-out-horizontal pull-right mt-1 mb-1 mr-1 onconfirm" onClick={this.handleSubmitSearch}>Search </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-12">

                                <div className="mb-4 normal-data bg-white p-2 rounded">
                                    <div className="card-body posrelative p-2">
                                        <div className="table-responsive dataTable unsubmitted posrel postion-right">
                                            <div className="export-div-xx right-0">
                                                <button className="export-class-x hvr-shutter-out-horizontal">Export to Excel</button>
                                            </div>
                                            <div className="col-md-2 searchoff nopadding">
                                                <span className="show-title">Show</span>
                                            </div>
                                            <div className="col-md-2 entriesoff nopadding">
                                                <span className="show-entries">entries</span>
                                            </div>
                                            <MaterialTable
                                                title=""
                                                tableRef={this.tableRef}
                                                onRowClick={(event, rowData) => {
                                                    localStorage.setItem('PrevPage', 'summaryedit');
                                                    let orderingOfficeOptionsList = JSON.stringify(this.state.orderingOfficeAccessList);
                                                    let selectedOrderingOfficeList = JSON.stringify(this.state.selectedMultiItem);
                                                    let selectedRows = JSON.stringify(this.state.SelectedRows);
                                                    localStorage.setItem('SelectedRows', selectedRows);
                                                    localStorage.setItem('OrderingOfficeOptionsList', orderingOfficeOptionsList);
                                                    localStorage.setItem('SelectedOrderingOfficeList', selectedOrderingOfficeList);
                                                    localStorage.setItem('SearchType', this.state.SearchType);
                                                    localStorage.setItem('ViewType', this.state.ViewType);
                                                    localStorage.setItem('DateType', this.state.DateType);
                                                    localStorage.setItem('StartDate', this.state.StartDate);
                                                    localStorage.setItem('EndDate', this.state.EndDate);
                                                    localStorage.setItem('CurrentPage', this.state.currentPage);
                                                    localStorage.setItem('ResultsSortColumn', this.state.sortColumn);
                                                    localStorage.setItem('ResultsSortOrder', this.state.sortOrder);
                                                    this.props.history.push('/case-details?id=' + rowData.encId);
                                                }}
                                                className="dataTable"
                                                localization={{ body: { emptyDataSourceMessage: <p>{this.state.emptyDataSourceMessage}</p> } }}
                                                columns={columns}
                                                data={this.state.SearchList}
                                                
                                                options={{
                                                    exportButton: true,
                                                    selection: false,
                                                    exportAllData: true,
                                                    paginationType: "stepped",
                                                    labelRowsPerPage: false,
                                                    search: false,
                                                    exportCsv: async (data, columns) => {
                                                        let isExport = await this.exportGridValues();
                                                        if (this.state.hideExportButton && isExport && this.state.dataForExport.length > 0) {
                                                            const columnTitles = data.filter(a => !a.hidden && a.field.toLowerCase() !== 'numberofresults' && a.field.toLowerCase() !== 'action').map(columnDef => columnDef.title);
                                                            const csvData = this.state.dataForExport.map(rowData =>
                                                                data.filter(a => !a.hidden && a.field.toLowerCase() !== 'numberofresults').map(columnDef => rowData[columnDef.field]),
                                                            );
                                                            const builder = new CsvBuilder(`ReviewOrdersList.csv`)
                                                                .setColumns(columnTitles)
                                                                .addRows(csvData)
                                                                .exportFile();
                                                            return Promise.resolve(builder);
                                                        } else {
                                                            this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                        }
                                                        return Promise.resolve(null);
                                                    },
                                                    exportPdf: async (data, columns) => {
                                                        let isExport = await this.exportGridValues();
                                                        if (this.state.hideExportButton) {
                                                            if (isExport) {
                                                                if (this.state.dataForExport.length > 0) {
                                                                    const unit = "pt";
                                                                    const size = "A4"; // Use A1, A2, A3 or A4
                                                                    const orientation = "landscape"; // portrait or landscape
                                                                    const doc = new jsPDF(orientation, unit, size);
                                                                    doc.text("Completed Order List", 40, 35);
                                                                    const columnTitles = data.filter(a => !a.hidden && a.field.toLowerCase() !== 'action' && a.field.toLowerCase() !== 'numberofresults').map(columnDef => columnDef.title);
                                                                    const pdfData = this.state.dataForExport.map(rowData =>
                                                                        data.filter(a => !a.hidden && a.field.toLowerCase() !== 'numberofresults').map(columnDef => rowData[columnDef.field]),
                                                                    );
                                                                    if (companyType === 'LIFE') {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 65 },
                                                                                1: { cellWidth: 65 },
                                                                                2: { cellWidth: 65 },
                                                                                3: { cellWidth: 65 },
                                                                                4: { cellWidth: 65 },
                                                                                5: { cellWidth: 65 },
                                                                                6: { cellWidth: 65 },
                                                                                7: { cellWidth: 65 },
                                                                                8: { cellWidth: 65 },
                                                                                9: { cellWidth: 65 },
                                                                                10: { cellWidth: 65 },

                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }
                                                                    else {
                                                                        doc.autoTable({
                                                                            styles: {
                                                                                cellPadding: 2,
                                                                                cellWidth: 'auto'
                                                                            },
                                                                            bodyStyles: {
                                                                                cellPadding: 2,
                                                                                margin: 40,
                                                                            },
                                                                            columnStyles: {
                                                                                0: { cellWidth: 50 },
                                                                                1: { cellWidth: 50 },
                                                                                2: { cellWidth: 50 },
                                                                                3: { cellWidth: 50 },
                                                                                4: { cellWidth: 50 },
                                                                                5: { cellWidth: 50 },
                                                                                6: { cellWidth: 50 },
                                                                                7: { cellWidth: 50 },
                                                                                8: { cellWidth: 50 },
                                                                                9: { cellWidth: 50 },
                                                                                10: { cellWidth: 50 },
                                                                                text: {
                                                                                    cellWidth: 'wrap'
                                                                                }
                                                                            },
                                                                            head: [columnTitles],
                                                                            body: pdfData
                                                                        });
                                                                    }

                                                                    doc.save(`ReviewOrdersList.pdf`);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            this.toasterredfun2("You have no access to download. Contact Administrator.");
                                                        }
                                                    }
                                                }}
                                                components={{
                                                    Header: (props) => (
                                                        <TableHead>
                                                            <TableRow>
                                                                {visibleColumns.map((column, index) => (
                                                                    <TableCell
                                                                        key={column.field}

                                                                    >
                                                                        {column.disableSorting ? (
                                                                            <span>{column.title}</span>
                                                                        ) : (
                                                                            <TableSortLabel
                                                                                active={this.state.sortColumn === column.field}
                                                                                direction={this.state.sortColumn === column.field ? this.state.sortOrder : 'asc'}
                                                                                onClick={() => this.handleColumnHeaderClick(column)}
                                                                            >
                                                                                {column.title}
                                                                            </TableSortLabel>
                                                                        )}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    ),
                                                    Pagination: props => (
                                                        <TablePagination
                                                            {...props}
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                            rowsPerPage={this.state.PageLimit}
                                                            count={this.state.totalRecord}
                                                            page={this.state.currentPage}
                                                            onChangeRowsPerPage={event => {
                                                                props.onChangeRowsPerPage(event);
                                                                this.setState({ PageLimit: event.target.value, currentPage: 0 });
                                                                localStorage.setItem('ClickedPageInResult', 1);
                                                                this.getGridValues(this.state.UserId, 0, event.target.value, this.state.sortColumn, this.state.sortOrder)
                                                            }}
                                                            onChangePage={(event, page) => {
                                                                let ClickedButton = event.nativeEvent.target.innerText;
                                                                localStorage.setItem('ClickedPageInResult', ClickedButton)
                                                                if (ClickedButton === "first_page") {
                                                                    this.setState({ currentPage: 0 })
                                                                    this.getGridValues(this.state.UserId, 0, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton === "chevron_left") {
                                                                    this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }));
                                                                    let Chevronleftvalue = this.state.currentPage - 1
                                                                    localStorage.setItem('ChevronleftInResult', Chevronleftvalue)
                                                                    let offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton === "chevron_right") {
                                                                    this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }));
                                                                    let Chevronrightvalue = this.state.currentPage + 1
                                                                    localStorage.setItem('ChevronrightInResult', Chevronrightvalue)
                                                                    let offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else if (ClickedButton === "last_page") {
                                                                    let lp = this.state.totalRecord / this.state.PageLimit;
                                                                    lp = Math.ceil(lp) - 1;
                                                                    let offSet;
                                                                    if (lp === 0) {
                                                                        this.setState({ currentPage: 1 })
                                                                        offSet = this.state.PageLimit
                                                                    }
                                                                    else {
                                                                        this.setState({ currentPage: lp })
                                                                        offSet = lp * this.state.PageLimit
                                                                    }
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                                else {
                                                                    let offSet;
                                                                    if (ClickedButton > this.state.currentPage) {
                                                                        this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }))
                                                                        offSet = (this.state.currentPage + 1) * this.state.PageLimit;
                                                                    }
                                                                    else {
                                                                        this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }))
                                                                        offSet = (this.state.currentPage - 1) * this.state.PageLimit;
                                                                    }
                                                                    this.getGridValues(this.state.UserId, offSet, this.state.PageLimit, this.state.sortColumn, this.state.sortOrder)
                                                                }
                                                            }}
                                                        />
                                                    ),
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"modal " + (this.state.isModalopen ? "show" : "hide")}
                    id="myModal3"
                >
                    <span className="alert-bg-div-x"></span>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">User Search</h4>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.onClickclosePopup}
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="col-md-12 mt-2 mb-2">
                                    <div className="col-md-12 pull-left mb-2">
                                        <div className="form-group posrelative-xx fadocsel">
                                            <Select
                                                className={
                                                    "hide form-control popAgent " +
                                                    (this.state.dopopAgentname
                                                        ? "redborder"
                                                        : "blackborder")
                                                }
                                                value={this.state.PopAgentId}
                                                options={this.state.AgentOptions}
                                                onChange={this.handlePopAgentNameChange}
                                                placeholder=""
                                                Clearable
                                                Loading
                                            />

                                            {this.renderAgentSearch()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 "
                                    onClick={this.handleReassign}
                                >
                                    Add{" "}
                                </button>
                                <button
                                    className="btn btn-blue-btnnm btn-type pull-right mt-1 mb-1 mr-1"
                                    onClick={this.onClickclosePopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
